




















import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { UserToFollowViewModel } from '../../viewmodels/user-to-follow-viewmodel'

@Observer
@Component({
  components: {
    'user-to-follow-item': () => import('./user-to-follow-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class UserToFollowList extends Vue {
  @Inject() vm!: UserToFollowViewModel

  loadMore(entry) {
    if (this.vm.userToFollowCanLoadMore) {
      if (entry.isIntersecting) this.vm.loadMore()
    }
  }

  goBack() {
    this.$router.go(-1)
  }
}
